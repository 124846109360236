import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ServerCard from "./components/ServerCard";
import ReadyToBuy from "../../components/ReadyToBuy";
import Extensions from "../../components/Extensions";

import ALMImg from "../../assets/img/Polarion-ALM.png";
import XImg from "../../assets/img/Polarion-X.png";
import SAFEImg from "../../assets/img/SAFe.png";
import SafetyImg from "../../assets/img/functional-safety.png";
import CyberSecImg from "../../assets/img/cyber-security-x.png";
import AppsImg from "../../assets/img/apps.png";

const Component = () => {
  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col className="col-12 col-lg-5 offset-lg-1">
            <h1
              style={{
                lineHeight: 1.3333333333,
                color: "white",
                paddingTop: "50px",
                paddingBottom: "12px",
              }}
            >
              Polarion Test Drives
            </h1>
            <p
              style={{
                fontWeight: "400",
                color: "white",
              }}
            >
              Take Polarion for a spin for free today and get a feel for the
              tool that helps more than 20,000 firms worldwide create better
              complex software faster.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="col-12 col-lg-10 offset-lg-1">
            <ServerCard
              url="https://testdrive.polarion.com/polarion"
              name="Polarion ALM"
              description="Everything you need to accelerate innovation in one Unified Solution. Connect teams and projects, and improve application development processes with a single, unified solution for requirements, coding, testing, and release."
              icon={ALMImg}
            />
            <ServerCard
              url="https://testdrive.polarion.com/polarion"
              name="Polarion X"
              description="Software Lifecycle Under Control in the Cloud. Everything you need to achieve agility and have full control over your cyber-physical systems application lifecycle."
              icon={XImg}
            />
            <ServerCard
              url="https://testdrive.polarion.com/polarion/#/project/PortfolioPolarionALM/home"
              name="SAFe®"
              description="Achieve agility throughout your organization with the Scaled Agile Framework for Polarion. Protect quality and traceability, keep your approval process as lean as possible but still stay compliant."
              icon={SAFEImg}
            />
            <ServerCard
              url="https://testdrive.polarion.com/polarion/#/project/laneFusionFuSa/home"
              name="Automotive Functional Safety"
              description="Our Functional Safety solution is based on ISO 26262, for addressing the safety of electrical and/or electronic (E/E) systems within road vehicles."
              icon={SafetyImg}
            />
            <ServerCard
              url="https://testdrive.polarion.com/polarion/#/project/userInterfaceCyberSec/home"
              name="Cybersecurity"
              description="Our Cybersecurity solution is based on ISO 21434, which provides a process framework for secure software development and post-production monitoring of vehicles for security-related issues."
              icon={CyberSecImg}
            />
            <ServerCard
              url="https://testdrive.polarion.com/polarion/#/wiki/PopularPolarionApps"
              name="Popular Polarion Apps"
              description="Extend your Polarion with product-like apps to boost user effectiveness, simplify user experience & strengthen your planning."
              icon={AppsImg}
            />
          </Col>
        </Row>
        <Row>
          <Col className="col-12 col-lg-5 offset-lg-1">
            <Extensions />
          </Col>
          <Col className="col-12 col-lg-5 ">
            <ReadyToBuy />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Component;
