import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

const Component = (props) => {
  return (
    <Row>
      <Col className="col-12">
        <div
          className="whiteBox"
          style={{
            paddingBottom: "30px",
          }}
        >
          <Row className="row">
            <Col
              className="col-0 col-lg-3 d-none d-lg-flex col"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                src={props.icon}
                class=""
                style={{ width: "160px", height: "160px", marginLeft: "20px" }}
              />
            </Col>
            <Col className="col-12 col-lg-6 col">
              <div style={{ fontSize: "32px", padding: "2rem 3rem 1rem 1rem" }}>
                {props.name}
                <p style={{ fontSize: "1rem" }}>{props.description}</p>
              </div>
              <a
                href={props.url}
                target="_blank"
                className="button cta"
                style={{
                  marginLeft: "1rem",
                  minWidth: "12rem",
                  textAlign: "left",
                  fontSize: "18px",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                  maxWidth: "70%",
                }}
                rel="noreferrer"
              >
                Launch
              </a>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default Component;
