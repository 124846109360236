import Container from "react-bootstrap/Container";

import "./index.scss";

const Component = () => {
  return (
    <Container id="footer" fluid={true}>
      <div>
        © Siemens 1996 - 2023
      </div>
    </Container>
  );
};

export default Component;
