const Component = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "8rem 0",
        marginTop: "1.5rem",
        backgroundColor: "#FFFFFF",
        boxShadow: "1px 2px 1px #D1D8DE",
        borderRadius: "16px",
      }}
    >
      <div
        style={{
          fontSize: "32px",
          fontWeight: "bold",
          marginBottom: "0.8rem",
        }}
      >
        Ready to Buy?
      </div>
      <div style={{ marginBottom: "3.65rem" }}>
        Or do you have any questions?
      </div>
      <div>
        <a
          href="https://polarion.plm.automation.siemens.com/company/contact"
          target="_blank"
          className="button cta"
          rel="noreferrer"
        >
          Contact Sales
        </a>
      </div>
    </div>
  );
};

export default Component;
