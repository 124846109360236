import Form from "react-bootstrap/Form";

const TextInput = ({ name, required, label, formik, disabled }) => {
  return (
    <Form.Group controlId={"form" + name}>
      <Form.Label>
        {label}
        {required ? <span className="required-bullet">&bull;</span> : null}
        {formik.touched[name] && formik.errors[name] ? (
          <span className="error-message">{formik.errors[name]}</span>
        ) : null}
      </Form.Label>
      <Form.Control
        type="text"
        name={name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values[name]}
        className={formik.touched[name] && formik.errors[name] ? "error" : null}
        // required={required}
        disabled={disabled ? true : false}
      />
    </Form.Group>
  );
};

export default TextInput;
