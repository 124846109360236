import Form from "react-bootstrap/Form";

const CheckBoxWithTextInput = ({ name, required, label, text, formik, children }) => {
  return (
    <Form.Group controlId={"form" + name} className="checkbox-with-text">
      <Form.Label>
        {label}
        {required ? <span className="required-bullet">&bull;</span> : null}
        {formik.touched[name] && formik.errors[name] ? (
          <span className="error-message">{formik.errors[name]}</span>
        ) : null}
      </Form.Label>
      <div className="checkbox-with-text-wrapper">
        <Form.Check
          type="checkbox"
          name={name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[name]}
          className={
            formik.touched[name] && formik.errors[name] ? "error" : null
          }
        />
        <Form.Label className="checkbox-with-text-label-text">
          {children}
        </Form.Label>
      </div>
    </Form.Group>
  );
};

export default CheckBoxWithTextInput;
