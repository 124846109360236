import Image from "react-bootstrap/Image";

import ExtensionEA from "../../assets/img/extensions/ea.png";
import Extension1 from "../../assets/img/extensions/extension1.svg";
import ExtensionHP from "../../assets/img/extensions/extensionHP.png";
import ExtensionMS from "../../assets/img/extensions/ms.png";
import ExtensionMatlab from "../../assets/img/extensions/matlab.png";
import ExtensionSAFe from "../../assets/img/extensions/safe.png";
import ExtensionSF from "../../assets/img/extensions/sf.png";
import ExtensionTasktop from "../../assets/img/extensions/tasktop.png";

import "./index.scss";

const Component = () => {
  return (
    <div className="home-extensions">
      <div className="home-extensions-title">Polarion Extensions</div>
      <div className="home-extensions-extensions">
        <div className="home-extensions-extensions-row">
          <div>
            <Image src={Extension1} />
          </div>
          <div>
            <Image src={ExtensionSAFe} />
          </div>
          <div>
            <Image src={ExtensionMatlab} />
          </div>
          <div>
            <Image src={ExtensionEA} />
          </div>
        </div>
        <div className="home-extensions-extensions-row">
          <div>
            <Image src={ExtensionTasktop} />
          </div>
          <div>
            <Image src={ExtensionMS} />
          </div>
          <div>
            <Image src={ExtensionSF} />
          </div>
          <div>
            <Image src={ExtensionHP} />
          </div>
        </div>
      </div>

      <div className="home-extensions-button-wrapper">
        <a
          href="https://extensions.polarion.com/"
          target="_blank"
          className="button cta"
          rel="noreferrer"
        >
          Extension Portal
        </a>
      </div>
    </div>
  );
};

export default Component;
