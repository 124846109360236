import Form from "react-bootstrap/Form";
import CountrySelect from "react-bootstrap-country-select";

const CountrySelectInput = ({ name, required, label, formik }) => {
  return (
    <Form.Group controlId={"form" + name}>
      <Form.Label>
        {label}
        {required ? <span className="required-bullet">&bull;</span> : null}
        {formik.touched[name] && formik.errors[name] ? (
          <span className="error-message">{formik.errors[name]}</span>
        ) : null}
      </Form.Label>
      <CountrySelect
        value={formik.values[name]}
        name={name}
        onChange={(value) => {
          formik.setFieldValue(name, value);
        }}
        valueAs="id"
        className={formik.touched[name] && formik.errors[name] ? "error" : null}
        placeholder=""
      />
    </Form.Group>
  );
};
export default CountrySelectInput;
