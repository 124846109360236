import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { useFormik } from "formik";
import * as Yup from "yup";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Input from "../../components/WhiteForm/TextInput";
import CountrySelectInput from "../../components/WhiteForm/CountrySelectInput";
import Checkbox from "../../components/WhiteForm/CheckBoxWithTextInput";
import { useRecoilValue, useRecoilState } from "recoil";
import jwtAtom from "../../atoms/jwt";
import userAtom from "../../atoms/user";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Component = () => {
  const jwt = useRecoilValue(jwtAtom);
  const [user, setUser] = useRecoilState(userAtom);

  const formik = useFormik({
    initialValues: {
      lastName: user.lastName || "",
      firstName: user.firstName || "",
      phone: user.phone || "",
      company: user.company || "",
      street: user.street || "",
      country: user.country || "",
      city: user.city || "",
      email: user.email || "",
      eula_accepted: user.eula_accepted || false,
      newsletter: user.newsletter || false,
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .min(2, "Your name is too short")
        .max(100, "Your name is too long")
        .required("We require your name"),
      lastName: Yup.string()
        .min(2, "Your name is too short")
        .max(100, "Your name is too long")
        .required("We require your name"),
      email: Yup.string()
        .required("Your email is required")
        .email("This is not a valid email"),
      phone: Yup.string().required("Your phone is required"),
      company: Yup.string().required("Your company is required"),
      street: Yup.string().required("Your street is required"),
      country: Yup.string().required("Your country is required"),
      city: Yup.string().required("Your city is required"),
      eula_accepted: Yup.boolean().isTrue(
        "We required consent with Terms and Conditions"
      ),
    }),
    onSubmit: (values) => {
      console.log(values);
      console.log(jwt);
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/me`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify(values),
      }).then(async (res) => {
        const user = await res.json();
        if (res.status === 200) {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
          setUser(user.data);
          localStorage.setItem("user", JSON.stringify(user.data));
        }
      });
    },
  });

  return (
    <>
      {user.eula_accepted && (
        <Header style={{ marginBottom: "2rem" }} active="profile" />
      )}
      {!user.eula_accepted && <Header showNavigation={false} />}
      <Container id="profile">
        {!user.eula_accepted && (
          <Row>
            <Col className="col-12 col-lg-5">
              <h1
                style={{
                  lineHeight: 1.3333333333,
                  color: "white",
                  paddingTop: "50px",
                  paddingBottom: "12px",
                }}
              >
                You're almost finished
              </h1>
              <p
                style={{
                  fontWeight: "400",
                  color: "white",
                }}
              >
                We'll need to know the following information to complete your
                profile.
              </p>
            </Col>
          </Row>
        )}
        <div className="profile">
          <Container
            className="whiteBox"
            style={{ marginTop: "0px", padding: "20px 20px" }}
          >
            <form className="whiteForm" onSubmit={formik.handleSubmit}>
              <div className="row">
                <div>
                  <Input
                    name="firstName"
                    required={true}
                    label="First Name"
                    formik={formik}
                  />
                  <Input
                    name="lastName"
                    required={true}
                    label="Last Name"
                    formik={formik}
                  />
                  <Input
                    name="email"
                    required={true}
                    label="Email"
                    formik={formik}
                    disabled={true}
                  />

                  <Input
                    name="phone"
                    required={true}
                    label="Phone"
                    formik={formik}
                  />

                  <Input
                    name="company"
                    required={true}
                    label="Company"
                    formik={formik}
                  />

                  <Input
                    name="street"
                    required={true}
                    label="Street"
                    formik={formik}
                  />

                  <Input
                    name="city"
                    required={true}
                    label="City"
                    formik={formik}
                  />

                  <CountrySelectInput
                    name="country"
                    label="Country"
                    required={true}
                    formik={formik}
                  />

                  {/* {isEulaAccepted && (
                      <CTA
                        style={{ marginTop: "40px" }}
                        onClick={() => {
                          setSubmitting(true);
                          submitForm();
                        }}
                        disabled={isSubmitting}
                      >
                        Update
                      </CTA>
                    )} */}

                  {!user.eula_accepted && (
                    <>
                      <Checkbox
                        label="Terms and Conditions"
                        name="eula_accepted"
                        required={true}
                        formik={formik}
                      >
                        I have read and agree to the{" "}
                        <a
                          href="https://www.plm.automation.siemens.com/en/products/eula/online-terms-sisw-cloud.shtml"
                          target="blank"
                        >
                          Polarion Cloud Services Agreement
                        </a>{" "}
                        and the{" "}
                        <a
                          href="https://www.plm.automation.siemens.com/store/en-us/trial/export.html"
                          target="blank"
                        >
                          Export Agreement
                        </a>
                        .
                      </Checkbox>

                      <Checkbox
                        label="Newsletter"
                        name="newsletter"
                        required={false}
                        formik={formik}
                      >
                        <div style={{ paddingBottom: "16px" }}>
                          Sign me up for emails about Siemens PLM Software
                          products and services.
                        </div>
                        <div style={{ paddingBottom: "16px" }}>
                          <strong>Notice:</strong> By supplying my contact
                          information, I authorize Siemens PLM Software and its
                          affiliates to contact me via email, phone, and postal
                          mail about its products and services as described in
                          detail{" "}
                          <a
                            href="https://www.plm.automation.siemens.com/global/en/legal/consent-declaration.html"
                            target="blank"
                          >
                            here
                          </a>
                          . Please visit our{" "}
                          <a
                            href="https://www.plm.automation.siemens.com/en/legal.shtml"
                            target="blank"
                          >
                            privacy policy
                          </a>
                          .
                        </div>
                      </Checkbox>
                    </>
                  )}
                  <Button
                    className="cta"
                    type="submit"
                    style={{ height: "5rem", width: "20rem" }}
                  >
                    Submit
                  </Button>
                </div>
                <div className="col-lg-1" />
              </div>
            </form>
          </Container>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default Component;
