import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useEffect } from "react";
import Home from "./pages/Home";
import WebkeyHowTo from './pages/WebkeyHowTo'
import Profile from "./pages/Profile";
import queryString from "query-string";
import { useRecoilState } from "recoil";
import jwtAtom from "./atoms/jwt";
import userAtom from "./atoms/user";
import { useQuery } from "react-query";

function App() {
  const [jwt, setJwt] = useRecoilState(jwtAtom);
  const [user, setUser] = useRecoilState(userAtom);

  useEffect(() => {
    const parsedQueryString = queryString.parse(window.location.search);
    if (parsedQueryString.jwt) {
      setJwt(parsedQueryString.jwt);
      localStorage.setItem("jwt", parsedQueryString.jwt);
      window.location.search = "";
    }
  }, [setJwt]);

  const { isLoading, error } = useQuery(
    "getUser",
    () =>
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/me`, {
        headers: { Authorization: `Bearer ${jwt}` },
      }).then(async (res) => {
        const user = await res.json();
        console.log(user.data);
        if (res.status === 200) {
          setUser(user.data);
          localStorage.setItem("user", JSON.stringify(user.data));
        }
      }),
    { enabled: !!jwt }
  );

  let router;

  if (!jwt) {
    // const redirectRouter = createBrowserRouter([
    //   {
    //     path: "/",
    //     element: (
    //       <Redirect
    //         url={`${process.env.REACT_APP_API_URL}/api/v1/authentication/webkey`}
    //       />
    //     ),
    //   },
    // ]);
    // return <RouterProvider router={redirectRouter} />;
    router = createBrowserRouter([
      {
        path: "/",
        element: <WebkeyHowTo />,
      },
    ]);
  } else if (isLoading || error || !user) {
    router = createBrowserRouter([
      {
        path: "/",
        element: (
          <div>
            <h1>Loading...</h1>
          </div>
        ),
      },
    ]);
  } else if (!user.eula_accepted) {
    router = createBrowserRouter([
      {
        path: "/",
        element: <Profile />,
      },
    ]);
  } else {
    router = createBrowserRouter([
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
    ]);
  }

  return <RouterProvider router={router} />;
}

export default App;
