import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ReadyToBuy from "../../components/ReadyToBuy";
import Extensions from "../../components/Extensions";
import WebkeyGuide from "./components/WebkeyGuide";

const Component = () => {
  return (
    <>
      <Header showNavigation={false} />
      <Container>
        <Row>
          <Col className="col-12 col-lg-5 offset-lg-1">
            <h1
              style={{
                lineHeight: 1.3333333333,
                color: "white",
                paddingTop: "50px",
                paddingBottom: "12px",
              }}
            >
              Polarion Test Drives
            </h1>
            <p
              style={{
                fontWeight: "400",
                color: "white",
              }}
            >
              Take Polarion for a spin for free today and get a feel for the
              tool that helps more than 20,000 firms worldwide create better
              complex software faster.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="col-12 col-lg-10 offset-lg-1">
            <WebkeyGuide />
          </Col>
        </Row>
        <Row>
          <Col className="col-12 col-lg-5 offset-lg-1">
            <Extensions />
          </Col>
          <Col className="col-12 col-lg-5 ">
            <ReadyToBuy />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Component;
