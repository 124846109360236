import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import WebkeyGif from "../../../../assets/img/webkey.gif";

const Component = (props) => {
  return (
    <Row>
      <Col>
        <div
          className="whiteBox"
          style={{
            paddingBottom: "30px",
          }}
        >
          <Row className="row">
            <Col className="col-lg-6 d-none d-md-block d-lg-flex col">
              <Image
                src={WebkeyGif}
                class=""
                style={{ padding: "30px 20px 0px 20px" }}
              />
            </Col>
            <Col
              className="col-lg-6 d-lg-flex col"
              style={{ flexDirection: "column" }}
            >
              <div style={{ padding: "70px 20px" }}>
                <div style={{ fontSize: "32px", paddingBottom: "15px" }}>
                  Get your Siemens WebKey
                </div>
                <div style={{ paddingBottom: "15px" }}>
                  WebKey allows you access across a wide selection of Siemens
                  products and services.
                </div>
                <ol style={{ lineHeight: "1.8rem" }}>
                  <li>
                    Follow this link&nbsp;
                    <a
                      href={
                        process.env.REACT_APP_API_URL +
                        "/api/v1/authentication/webkey"
                      }
                      style={{ textDecoration: "none" }}
                    >
                      Siemens WebKey Sign Up
                    </a>
                  </li>
                  <li>
                    Enter your email address and click <b>Continue</b>
                  </li>
                  <li>
                    Click on <b>Create an account</b>
                  </li>
                  <li data-outlined="false" class="">
                    Fill in your contact details and click <b>Create account</b>
                    <br /> or used preferred platform to sign up
                  </li>
                  <li data-outlined="false" class="">
                    Verify email address which is in your <b>Inbox</b>
                    <br />
                    Once verified click on <b>Continue</b>
                  </li>
                  <li data-outlined="false" class="">
                    Click on <b>Allow</b>
                  </li>
                  <li data-outlined="false" class="">
                    Your Siemens WebKey is now ready for use with Polarion
                    MyAccount.{" "}
                  </li>
                </ol>
                <div style={{ marginTop: "100px" }}>
                  <a
                    href={
                      process.env.REACT_APP_API_URL +
                      "/api/v1/authentication/webkey"
                    }
                    className="button cta"
                    style={{ width: "70%", display: "block" }}
                  >
                    Login or Get your WebKey
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default Component;
