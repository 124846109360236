import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

import "./index.scss";

const Component = ({ style, active = "instances", showNavigation = true }) => {
  return (
    <Navbar
      expand="lg"
      style={{ ...style, display: "flex", flexDirection: "column" }}
    >
      <Container
        className="firstRow"
        fluid={true}
        style={
          showNavigation
            ? {}
            : { borderBottom: "2px solid #00e6dc", paddingBottom: "15px" }
        }
      >
        <Col style={{ display: "flex" }}>
          <Navbar.Brand href="/">
            <div className="logo"></div>
          </Navbar.Brand>
        </Col>
        <Col className="text-center navbar-title d-none d-lg-block">
          <div>Polarion My Account</div>
        </Col>
        <Col className="text-end navbar-readyToBuy d-none d-lg-block">
          <a
            href="https://polarion.plm.automation.siemens.com/company/contact"
            target="_blank"
            className="cta"
            rel="noreferrer"
          >
            Ready to Buy? Or any questions?
          </a>
          <a
            href="https://polarion.plm.automation.siemens.com/company/contact"
            target="_blank"
            className="button cta"
            rel="noreferrer"
            style={{
              marginLeft: 20,
            }}
          >
            Contact Sales
          </a>
        </Col>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
      </Container>
      {showNavigation && (
        <Container className="secondRow" fluid={true}>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
              <Link
                className={
                  active === "instances" ? "nav-link active" : "nav-link"
                }
                to="/"
              >
                <span>Trials</span>
              </Link>
              <Link
                className={
                  active === "profile" ? "nav-link active" : "nav-link"
                }
                to="/profile"
              >
                <span>Your Profile</span>
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      )}
    </Navbar>
  );
};

export default Component;
